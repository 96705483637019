<template>
  <div style="height: 300px;">
    <a-table-f-api v-if="id"
      :api="api"
      :model="model"
      :searchable="false"
      :defaults="{filters: {caller_item_id: id}}"
    />
  </div>
</template>

<script>
import genModelBase from "@/share/components/libs/genModelBase";

export default {
  mixins: [genModelBase],
  props: {
    id: [String, Number],
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    api() {
      return this.$root.api.cardEvent;
    },
    model() {
      return this.generateModel("index", 'caller_events')
    }
  }
};
</script>